<template>
  <section class="dtc-main-section">
     <div class="dtc-save-print-btn d-print-none">
          <b-button
            variant="success"
            @click="save"
            class="d-print-none mr-2">
            <i class="fas fa-save mr-1"></i>儲存變更
          </b-button>
          <b-button variant="danger" @click="printDtxPage" class="d-print-none" hidden>
            <i class="fas fa-print mr-1"></i>列印
          </b-button>
    </div>
    
    <h3><span class="dtc-title">衛生福利部原住民及離島地區醫事人員</span> <br >養成計畫公費生服務期滿證明申請書</h3>
    <div class="dtc-words">
     申請人 <input v-model="map.StudentName" />係    <input  v-model="map.SchoolName"/>     學校   <input  v-model="map.MajorName"/>   系畢業，自民國   <input  v-model="startY"/> 年  <input  v-model="startM"/> 月  <input  v-model="startD"/>日起開始服務，至民國</br>

        <input  v-model="endY"/> 年    <input  v-model="endM"/>月   <input  v-model="endD"/>日止（訓練 <input v-model="map.TrainingYear"/>年，服務   <input v-model="map.ServingYear"/> 年）因服務期滿（檢附服務年資核計表及相關證明文件   <input v-model="map.Copies"/>  份），請惠予核發服務期滿證明書。
    </div>

    <h4 class="mt-3">
          此致
    </h4>
    <h4>
      衛生福利部
    </h4>
    <footer class="dtc-f1">
      <div>
        <div class>申請人(簽章):</div>
        <input v-model="map.ApplyName"/>
      </div>
      <div>
        <div>證書字號：</div>
        <input v-model="map.ApplyId"/>
      </div>
      <div>
        <div>連絡地址</div>
       <input v-model="map.ApplyAddrs"/>
      </div>
      <div>
        <div>住宅電話：</div>
        <input <input v-model="map.ApplyTel"/>
      </div>
      <div>
        <div>行動電話：</div>
        <input <input v-model="map.ApplyMobile"/>
      </div>
    </footer>

    <div class="dtc-f2">中華民國 <input v-model="year" />年
      <input v-model="month" />月
      <input v-model="day" />日</div>
  </section>
</template>

<script>
import queryString from "query-string";
const urlPost = "applyform2/Post";
const urlPut = "applyform2/Put";
const urlGet = "applyform2/Get?Identifier=";
const zero = "T00:00:00";


export default {
  name: "totalSpend",
  data() {
     return {
      countrys: [],
      map: {},
      method: "post",
      year: "",
      month: "",
      day: "",
      startY:"",
      startM:"",
      startD:"",
      endY:"",
      endM:"",
      endD:"",
    };
  },

  methods: {
    printDtxPage() {
      window.print();
    },
     async save() {
      if(this.startY){
        const startY = Number(this.startY) +  1911;
        this.startM = this.startM.length == 1 ? "0" + this.startM : this.startM;
        this.startD = this.startD.length == 1? "0" + this.startD : this.startD;
        this.map.StartDate = startY + "-" + this.startM + "-" + this.startD;
      }
      if(this.endY){
        const endY = Number(this.endY) +  1911;
        this.endM = this.endM.length == 1  ? "0" + this.endM : this.endM;
        this.endD = this.endD.length == 1 ? "0" + this.endD : this.endD;
        this.map.EndDate = endY + "-" + this.endM + "-" + this.endD;
      }

      if (this.year) {
        const year = Number(this.year) + 1911;
        this.month = this.month.length == 1 ? "0" + this.month : this.month;
        this.day = this.day.length == 1 ? "0" + this.day : this.day;
        this.map.CreateDate = year + "-" + this.month + "-" + this.day + zero;
      }
      try {
        if (this.method == "post") {
          await window.axios.post(urlPost, this.map);
          //await window.axios.put(urlPut, this.map);
        } else {
          await window.axios.put(urlPut, this.map);
        }
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {
        alert("save fail " + e);
      }
    },
  },
  async mounted() {
     try {
      const {
        Identifier = "H102463549",
        SchoolName = "臺北醫學大學",
        StudentName = "林勝利",
        MajorName = "醫學系",
        method = "post"
      } = queryString.parse(location.href.split("?")[1]);
      this.method = method;
      this.map = await window.axios.get(urlGet + Identifier);
      [this.startY, this.startM, this.startD] = this.map.StartDate.split("-");
      this.startY = Number(this.startY) - 1911;

       [this.endY, this.endM, this.endD] = this.map.EndDate.split("-");
      this.endY = Number(this.endY) - 1911;

      const [year, month, day] = this.map.CreateDate
        ? this.map.CreateDate.split("T")[0].split("-")
        : {};

      if (year) {
        this.year = Number(year) - 1911;
        this.month = month;
        this.day = day;
      }
    } catch (e) {
    }
  },
  beforeMount() {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-title {
  display: inline-block;
  margin-left: -12px;
}
.dtc-main-section {
  max-width: 890px;
  margin: auto;
  display: grid;
  --dtc-border-color: var(--dark);
  input {
    max-height: 30px;
    display: inline-block;
    margin: 0 12px;
    border: none;
    border-bottom: 1px solid var(--dtc-border-color);
    outline: none;
  }
}
.dtc-words {
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  input {
    text-align: center;
  }
}
footer {
  display: grid;
  margin-top: 3rem;
}
footer {
  > div {
    margin: 8px 0;
    display: grid;
    input {
      min-width: 600px;
    }
    grid-template-columns: 120px max-content;
  }
}
.dtc-f2 {
  margin-top: 3rem;
  input {
    text-align: center;
  }
}
h3 {
  margin: auto;
  margin-bottom: 18px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    //size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark);
  }
}
</style>
